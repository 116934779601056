import { useEffect, useState, useContext } from "react";
import { NextPage } from "next";
import { VesselContext } from "pages/_app";
import FadeIn from "react-fade-in";
import allVesselsMetadata from "metadata/vessels.json";

const mockSeeds = [1, 2, 3, 4];

type SiteText = {
    header: string;
    body: string;
};

type TextAreaProps = {
    text: string;
};

const siteText: SiteText[] = [
    {
        header: "Wisdom Vessels",
        body: "1,111 unique, dynamic, webGL, generative NFT artworks and all producers of $WISDOM ERC20, by artist collective Keiken.",
    },
    {
        header: "$WISDOM",
        body: "Each Wisdom Vessel produces $WISDOM. Vessel owners can harvest their $WISDOM and spend it in the Neknel marketplace.",
    },
    {
        header: "Wisdom Seeds",
        body: "Transcriptions from conversations with experts and friends have been comprehended as a whole by an LLM (Large Language Model) to train an AI, and in turn generate new pearls of wisdom for our first collection.",
    },
    {
        header: "Worlding",
        body: "The creator behind Wisdoms for Neknel is Keiken, an award-winning artist collective who create speculative worlds through filmmaking, gaming, installation, XR, blockchain and performance.",
    },
];

export const Home: NextPage = () => {
    const [currIndex, setCurrIndex] = useState(0);
    const [currTextIndex, setCurrTextIndex] = useState(0);
    const [mainText, setMainText] = useState(siteText[0]);

    const { setCurrentVessel } = useContext(VesselContext);

    function cycleArray() {
        // reset counter if we reach end of array
        if (currIndex === mockSeeds.length - 1) {
            setCurrIndex(0);
        } else setCurrIndex(currIndex + 1);
    }

    setTimeout(cycleArray, 6000);

    useEffect(() => {
        setCurrentVessel(allVesselsMetadata[mockSeeds[currIndex]]);
        setMainText(siteText[currIndex]);
    }, [currIndex]);

    const TextArea: React.FC<TextAreaProps> = ({ text }) => {
        return (
            <FadeIn delay={200}>
                <div className=" flex flex-col gap-4 text-white">
                    <h1 className={"text-3xl lg:text-4xl drop-shadow-lg pb-4"}>
                        {text}
                    </h1>
                    <p className={"text-base lg:text-lg drop-shadow-lg"}>
                        {mainText?.body}
                    </p>
                    <p className="self-end justify-end">{currIndex + 1}/4</p>
                </div>
            </FadeIn>
        );
    };

    return (
        <>
            <div className="lg:w-[500px] mx-auto px-12 w-full lg:mt-[35vh] mt-[60vh] lg:mb-0 absolute lg:right-0">
                <div className="flex flex-col gap-4 mx-2 md:mx-0">
                    {mainText && <TextArea text={mainText.header} />}
                </div>
            </div>
        </>
    );
};

export default Home;
